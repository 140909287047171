









































import { defineComponent } from "@vue/composition-api";
import { Location } from "vue-router";
import { setRefreshRequest as setAktivitasRefreshRequest } from "@/apps/aktivitas/modules/stores/aktivitas";
import { setRefreshRequest as setKIRefreshRequest } from "@/apps/karyailmiah/modules/store";
import { setRefreshRequest as setPerawatanRefreshRequest } from "@/apps/perawatan/modules/stores/perawatan";
import { setRefreshRequest as setUjianRefreshRequest } from "@/apps/ujian/modules/store";
import router from "@/router";

const RouteNameFunctionMap: Record<string, Function> = {
  "ujian-list": setUjianRefreshRequest,
  "karyailmiah-list": setKIRefreshRequest,
  "aktivitas-list": setAktivitasRefreshRequest,
  "perawatan-list": setPerawatanRefreshRequest,
};

export default defineComponent({
  name: "CardInfo",
  props: {
    context: { type: String, required: true },
    filterMap: Object,
    title: { type: String, required: true },
    subtitle: [String, Number],
    lastUpdate: String,
    targetName: { type: String, required: true },
    residenId: String,
    imageSrc: String,
    pencapaian: { type: Boolean, required: false },
  },
  setup(props) {
    let src = "";
    if (props.imageSrc)
      src = require(`@/apps/accounts/assets/img/${props.imageSrc}`);
    const navigateToTarget = () => {
      if (props.targetName in RouteNameFunctionMap) {
        RouteNameFunctionMap[props.targetName](props.context, props.filterMap);
      }
      const routeTo: Location = { name: props.targetName };
      router.push(routeTo);
    };

    return {
      // data
      src,

      // Method
      navigateToTarget,
    };
  },
});
